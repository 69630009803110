<template>
  <div class="shadow-sm">
    <div class="bg-dark">
      <b-container class="p-0">
        <div class="d-flex topbar">
          <router-link to="/" class="text-decoration-none">Are You Candidate?</router-link>
          <router-link to="/are-you-employer" class="text-decoration-none">Are You Employer?</router-link>
        </div>
      </b-container>
    </div>
    <b-navbar toggleable="lg" type="light" variant="white">
      <b-container class="p-0">
        <router-link to="/" class="navbar-brand">
          <img src="../assets/logo2x.png" alt="logo" width="250">
        </router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ms-auto fw-semibold">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name:'jobresults'}" class="nav-link">View All Jobs</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name:'areyouemployer'}" class="nav-link">Employers</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About Us</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact Us</router-link>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "MenuBar"
}
</script>

<style scoped>
.topbar a {color: #fff;font-size: 80%;padding: 5px 5px;margin:2px 3px;font-weight: 500;}
.topbar a.router-link-exact-active {color: #fff;background-color: var(--org);}
</style>